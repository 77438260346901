import { Resource } from '@/models/training/admin/section/video/video-section-form.model';

export class VideoType {
  public name: string;

  public content: string;

  public image: string;

  public addResource: string;

  public resources: Resource[];
}
