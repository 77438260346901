<template>
  <div>
    <v-col cols="12" sm="6">
      <div class="options__title">{{ $t('admin.training.admin.section.name') }}</div>
      <v-text-field
        single-line
        v-model="name"
        hide-details="auto"
        :persistent-placeholder="true"
        placeholder="-"
      />
    </v-col>
    <v-col cols="12" sm="6">
      <div class="options__title">{{ $t('admin.training.admin.section.description') }}</div>
      <v-text-field
        single-line
        v-model="description"
        hide-details="auto"
        :persistent-placeholder="true"
        placeholder="-"
      />
    </v-col>
    <v-col cols="12" sm="6" v-if="false">
      <v-btn class="mr-3 upload-file" color="primary" @click="dialog.accessFile = true">
        <div class="mx-3">
          {{ $t('admin.training.admin.section.accessFile') }}
        </div>
        <v-icon>mdi-folder</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" sm="12">
      <div class="options__title">{{ $t('admin.training.admin.section.approximateTime') }}</div>
      <v-menu
        ref="menu"
        v-model="menuTime"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="approximateTime"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="app-time"
            single-line
            hide-details="auto"
            v-model="approximateTime"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menuTime"
          v-model="approximateTime"
          format="24hr"
          full-width
          @click:minute="$refs.menu.save(approximateTime)"
        ></v-time-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="12">
      <div>
        <v-btn icon color="secondary" @click="toggleValue = !toggleValue">
          <v-icon
            v-if="toggleValue"
            size="40"
            color="primary"
            :title="$t('admin.users.list.deactivate')"
          >
            mdi-toggle-switch
          </v-icon>
          <v-icon size="40" v-else :title="$t('admin.users.list.activate')">
            mdi-toggle-switch-off
          </v-icon>
        </v-btn>
        {{ $t('trainings.addResources') }}
      </div>
      <div v-if="toggleValue">
        <v-btn class="mr-3 upload-file my-2" color="primary" @click="documentDialog = true">
          {{ $t('globals.upload') }}
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn
          :key="`doc-key-${doc.file}`"
          class="mr-3 my-2"
          color="white"
          v-for="doc in resourceList"
        >
          <span class="text-truncate" style="max-width: 100%">{{ doc.content || '' }}</span>
          <v-icon color="red" @click="removeDocument(doc)">mdi-close</v-icon>
        </v-btn>
      </div>
    </v-col>
    <div class="d-flex justify-end pr-6 my-3">
      <v-btn color="error" outlined="" data-test-id="cancel" class="mx-2" @click="resetLesson"
        >Cancel</v-btn
      >
      <v-btn color="primary" data-test-id="save" @click="saveLesson" :loading="isSaving">
        Save
      </v-btn>
    </div>
    <upload-files
      :is-multiple="true"
      :dialog="documentDialog"
      @save="uploadDocuments"
      @close="closeDocumentDialog"
    />
    <v-dialog
      v-model="dialog.accessFile"
      v-if="dialog.accessFile"
      width="800"
      @click:outside="dialog.accessFile = false"
    >
      <v-card>
        <v-card-title class="font-weight-bold">
          {{ $t(`admin.training.trainingList.popup.title.accessFile`) }}
        </v-card-title>
        <v-card-text>
          <AccessFileRepositoryDialog></AccessFileRepositoryDialog>
        </v-card-text>
        <v-card-actions class="actions"> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VideoTypeEnum } from '@/models/training/admin/section/video-type.enum';
import trainingLessonService from '@/services/training-lesson/training-lesson.service';
import { VideoType } from '@/models/training/admin/section/video/resource-section-form.model';
import { mapActions, mapGetters } from 'vuex';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  CURRENT_UPDATE_ITEM,
  TRAINING_LESSON_ADMIN_MODULE,
  UPDATE_TRAINING_LESSON,
} from '@/stores/umanize-admin/actions/training-lesson/training-lesson.actions';
import UploadFiles from '@/components/admin/upload-files/UploadFiles.vue';
import AccessFileRepositoryDialog from './access-file-dialog/AccessFileDialog.vue';

export default {
  name: 'FileRepositoryForm',
  inject: ['listSectionUpdate'],
  components: {
    AccessFileRepositoryDialog,
    UploadFiles,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    dialog: {
      accessFile: false,
    },
    name: '',
    description: '',
    menuTime: false,
    approximateTime: 0,
    videoForm: new VideoType(),
    toggleValue: false,
    resourceList: [],
    isSaving: false,
    documentDialog: false,
    rawItem: null,
  }),
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
  },
  methods: {
    ...mapActions(TRAINING_LESSON_ADMIN_MODULE, [UPDATE_TRAINING_LESSON, CURRENT_UPDATE_ITEM]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    collapLesson() {
      if (this.item) {
        this[CURRENT_UPDATE_ITEM](JSON.parse(JSON.stringify(this.item)));
      }
    },
    closeDocumentDialog() {
      this.documentDialog = false;
    },
    async uploadDocuments(documents) {
      const newList = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (const document of documents) {
        await this[UPLOAD_FILE](document);
        newList.push({
          content: document.name,
          file: this.file.url,
        });
      }
      this.resourceList = [...this.resourceList, ...newList];
      this.closeDocumentDialog();
    },
    removeDocument(doc) {
      const newList = this.resourceList.filter((ele) => ele.file !== doc.file);
      this.resourceList = newList;
    },
    getApproximateTime() {
      if (this.approximateTime) {
        const val = String(this.approximateTime).split(':');
        return Number(val[0]) * 60 + Number(val[1]);
      }
      return 0;
    },
    convertMinuteToTime(value) {
      if (!value) return '00:00';
      const hour = Math.floor(value / 60);
      const remainingMinutes = value % 60;
      const hhmm = `${hour.toString().padStart(2, '0')}:${remainingMinutes
        .toString()
        .padStart(2, '0')}`;
      return hhmm;
    },
    async saveLesson() {
      this.isSaving = true;
      const { sectionId, trainingId } = this.item;
      const { eventId } = this.$route.params;
      const priorityUpdated = this.listSectionUpdate?.list?.find((e) => e.id === this.item.id)
        ?.priority;
      const saveItem = JSON.parse(
        JSON.stringify({
          ...this.item,
          priority: priorityUpdated ?? this.item.priority,
          type: VideoTypeEnum.FILE_REPOSITORY,
          name: this.name,
          content: {
            files: {
              description: this.description,
              accesFiles: this.resourceList,
              approximateTime: this.getApproximateTime(),
            },
          },
        }),
      );
      await this.UPDATE_TRAINING_LESSON({
        eventId,
        sectionId,
        trainingId,
        upDateListLessionState: true,
        lesson: saveItem,
      });
      this.changeName(this.name);
      this.rawItem = saveItem;
      this.isSaving = false;
      this.collapLesson();
    },
    resetLesson() {
      this.$emit('reset-content', this.rawItem);
      this.init(this.rawItem);
      this.collapLesson();
    },
    init(item) {
      this.name = item.name;
      this.changeName(item.name);
      this.description = item.content.files.description;
      this.approximateTime = this.convertMinuteToTime(
        Number(item.content.files.approximateTime ?? 0),
      );
      this.resourceList = item.content.files?.accesFiles ?? [];
      if (this.resourceList.length) {
        this.toggleValue = true;
      }
    },
    changeName(name) {
      this.$emit('changeName', name);
    },
  },
  async mounted() {
    if (this.item) {
      const { eventId } = this.$route.params;
      const data = await trainingLessonService.get(
        eventId,
        this.item.trainingId,
        this.item.sectionId,
        this.item.id,
      );
      this.init(data);
      this.rawItem = JSON.parse(JSON.stringify(data));
    }
  },
  watch: {
    toggleValue(newVal) {
      if (!newVal) {
        this.resourceList = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '/src/styles/variables';
@import '/src/styles/core/mixins';

.options {
  &__title {
    font-weight: bold;
  }
}
</style>
