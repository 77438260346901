<template>
  <div class="#access-file-repository-dialog">
    <base-list
      className="table-content"
      backgroundColor="var(--v-adminBackground-base)"
      :items="fileItems"
      :loading="fileLoading"
      :headerProps="{ sortIcon: null }"
      :headers="headers"
      :search="search"
      :hideDefaultHeader="hideDefaultHeader"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
      :isSortDisabled="false"
      :itemPerPage="5"
    >
      <template v-slot:[`item.name`]="{ item }">
        <div class="ml-1">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:[`item.public`]="{ item }">
        <a
          ><u>
            {{ item.fileName }}
          </u>
        </a>
      </template>
      <template v-slot:[`item.actions`]="{}">
        <div class="d-flex justify-space-around">
          <div>
            <v-icon>mdi-download</v-icon>
          </div>
          <div>
            <v-icon>mdi-delete</v-icon>
          </div>
        </div>
      </template>
    </base-list>
  </div>
</template>
<script>
import BaseList from '@/components/base-list/BaseList.vue';

export default {
  name: 'AccessFileRepositoryDialog',
  components: {
    BaseList,
  },
  data: () => ({
    fileLoading: false,
    hideDefaultHeader: true,
  }),
  computed: {
    fileItems() {
      return [
        { id: 0, name: 'User 0', fileName: 'Document ultra important.pdf' },
        { id: 1, name: 'User 1', fileName: 'Document ultra important.pdf' },
        { id: 2, name: 'User 2', fileName: 'Document ultra important.pdf' },
        { id: 3, name: 'User 3', fileName: 'Document ultra important.pdf' },
        { id: 4, name: 'User 4', fileName: 'Document ultra important.pdf' },
      ];
    },
    headers() {
      return [
        {
          value: 'name',
          width: '30%',
        },
        {
          value: 'public',
          align: 'left',
          width: '50%',
        },
        {
          value: 'actions',
          align: 'center',
          width: '10%',
        },
      ];
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '/src/styles/variables';
@import '/src/styles/core/mixins';

#access-file-repository-dialog {
  .options {
    &__title {
      font-weight: bold;
    }
  }

  a {
    color: var(--v-anchor-base);
  }

  ::v-deep .table-content {
    background: red;
    .v-data-footer {
      justify-content: start !important;
    }
  }
}
</style>
