<template>
  <div>
    <v-card-title class="font-weight-bold">
      {{ $t(`admin.training.admin.section.addQuestion`) }}
    </v-card-title>

    <v-card-text class="pb-0 mb-0">
      <div id="dialog-question">
        <v-row>
          <v-col cols="12" sm="5">
            <p class="font-weight-bold black--text">
              {{ $t('admin.training.admin.section.question') }}
            </p>
            <vue-editor
              v-model="content"
              :editorToolbar="customToolbar"
              :placeholder="$t('admin.training.admin.section.writeHere')"
            ></vue-editor>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="5">
            <p class="font-weight-bold black--text mb-0">
              {{ 'Score' }}
            </p>
            <v-text-field
              v-model="score"
              :persistent-placeholder="true"
              aria-valuemax="100"
              class="pt-0"
              hide-details="auto"
              placeholder="-"
              single-line
              type="number"
            />
          </v-col>
        </v-row>
        <v-row class="my-0 py-0">
          <v-col class="my-0 py-0" cols="12" sm="6">
            <v-row class="my-0 py-0">
              <v-col class="d-flex align-center my-0 py-0" cols="12" sm="12">
                <div class="text-center">
                  {{ $t('admin.training.admin.section.selectCorrectAnswer') }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-for="(answer, index) in answers" :key="index" class="my-0 py-0">
          <v-col class="my-0 py-0" cols="12" sm="6">
            <v-row>
              <v-col class="d-flex align-center justify-center" cols="4" sm="4">
                <v-checkbox v-model="answer.isCorrect" class="mt-3"></v-checkbox>
              </v-col>
              <v-col cols="7" sm="7">
                <div class="black--text font-weight-bold">
                  {{ $t('admin.training.admin.section.answer') }} #{{ index + 1 }}
                </div>
                <v-text-field
                  v-model="answer.content"
                  class="mt-0 pt-0"
                  placeholder="..."
                ></v-text-field>
              </v-col>
              <v-col class="d-flex align-center justify-center" cols="1" sm="1">
                <v-icon class="mb-4 clickable" @click="answers.splice(index, 1)">mdi-delete</v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="add-answer clickable" @click="answers.push({ content: '', isCorrect: false })">
          {{ $t('admin.training.admin.section.addAnAnswerChoice') }}
          <v-icon class="mb-1" color="primary" size="15px">mdi-plus</v-icon>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="actions">
      <v-btn color="primary" data-test-id="cancel" outlined @click="closeDialog">
        {{ $t('globals.cancel') }}
      </v-btn>

      <v-btn color="primary" data-test-id="add" @click="saveQuestion">
        {{ $t('globals.save') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'DialogQuestion',
  components: {
    VueEditor,
  },
  props: {
    question: {
      required: true,
    },
  },
  data: () => ({
    content: '',
    selected: null,
    score: 0,
    customToolbar: [['bold', 'italic', 'underline']],
    answers: [],
  }),
  computed: {},
  methods: {
    closeDialog() {
      this.$emit('closeDialog', 'question');
    },
    saveQuestion() {
      this.$emit('saveQuestion', {
        answers: this.answers,
        content: this.content,
        score: Number(this.score),
      });
      this.$emit('closeDialog', 'question');
    },
  },
  mounted() {
    this.answers = this.question.answers ?? [];
    this.content = this.question.content;
    this.score = this.question.score;
  },
};
</script>

<style lang="scss">
#dialog-question {
  .v-text-field > .v-input__control > .v-input__slot:after {
    border: unset;
  }

  .v-text-field > .v-input__control > .v-input__slot:before {
    border: unset;
    border-bottom: 1px solid var(--v-primary-base);
  }

  .quillWrapper {
    .ql-toolbar {
      border: unset;
      padding: 0;

      .ql-formats {
        border: 1px solid var(--v-primary-base);
        border-right: unset;

        button {
          border-right: 1px solid var(--v-primary-base);
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2rem;
          width: 2rem;

          svg {
            color: var(--v-primary-base);
            filter: invert(55%) sepia(62%) saturate(2110%) hue-rotate(135deg) brightness(89%)
              contrast(101%);
          }
        }

        .ql-active {
          background: #5ae2d18f;
        }
      }
    }

    #quill-container {
      border: 1px solid var(--v-primary-base);
      margin-top: -5px;

      .ql-editor {
        min-height: unset;
        height: 100px;
      }

      .ql-editor.ql-blank::before {
        color: var(--v-primary-base);
        opacity: 0.6;
        font-style: normal;
      }
    }
  }

  .add-answer {
    color: var(--v-primary-base);
  }

  .clickable {
    cursor: pointer;
  }
}
</style>
