<template>
  <v-dialog content-class="v-dialog--overlay" v-model="open" width="500">
    <v-form v-model="valid">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $tc('admin.video.video', count) }}</span>
        </v-card-title>

        <v-card-text class="form">
          <loading v-if="isLoading" />
          <span>{{ $tc('admin.video.uploadText') }}</span>

          <v-divider />

          <v-file-input
            v-model="files"
            :rules="videoRules"
            show-size
            accept=".mp4,.mov,.wmv,.avi,.flv"
            :label="$tc('admin.video.placeholder', count)"
          />
        </v-card-text>

        <v-card-actions class="actions">
          <v-btn color="primary" class="mx-2" outlined @click="close">
            {{ $t('globals.cancel') }}
          </v-btn>

          <v-btn :disabled="isSaveDisabled" color="primary" @click="save">
            {{ $t('globals.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';
import Loading from '@/components/loading/Loading.vue';

export default {
  components: { Loading },
  name: 'UploadVideos',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valid: false,
    files: null,
    videoRules: [VALIDATORS.VIDEO.UPLOAD.IS_VIDEO, VALIDATORS.VIDEO.UPLOAD.SIZE],
  }),
  computed: {
    count() {
      return this.isMultiple ? 2 : 1;
    },
    isSaveDisabled() {
      return (this.files || []).length <= 0 || !this.valid;
    },
    open: {
      get() {
        return this.dialog;
      },
      set() {
        return this.close();
      },
    },
  },
  methods: {
    save() {
      this.$emit('save', this.files);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

.form {
  display: flex;
  flex-direction: column;
}
</style>
