<template>
  <div id="section-video-form">
    <v-col cols="12" sm="6">
      <div class="options__title">{{ $t('admin.training.admin.section.name') }}</div>
      <v-text-field
        single-line
        v-model="name"
        hide-details="auto"
        :persistent-placeholder="true"
        placeholder="-"
      />
    </v-col>
    <v-col cols="12" sm="12">
      <div class="options__title">{{ $t('admin.training.admin.section.content') }}</div>
      <div class="">{{ $t('admin.training.admin.section.videoUrlForEmbed') }}</div>
      <div class="d-flex justify-center">
        <v-row>
          <v-col cols="6" sm="6">
            <v-text-field
              v-model="content"
              :rules="[(v) => !!v || 'Video Url is required']"
              hide-details="auto"
              :persistent-placeholder="true"
              placeholder="-"
            />
          </v-col>
          <v-col cols="6" sm="6">
            <div class="d-flex align-center mt-4">
              <div class="mx-3">or</div>
              <v-btn class="mr-3 upload-file" color="primary" @click="dialog.video = true">
                {{ $t('globals.upload') }}
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" sm="6">
            <div class="options__title">{{ $t('admin.training.admin.section.description') }}</div>
            <v-textarea rows="1"></v-textarea>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col cols="12" sm="6">
      <div class="options__title">{{ $t('admin.training.admin.section.thumbnailImage') }}</div>
      <div class="d-flex align-center">
        <v-checkbox :value="putAnImage" v-model="putAnImage" hide-details></v-checkbox>
        <div @click="putAnImage = putAnImage ? 0 : 1" style="cursor: pointer">
          {{ $t('admin.training.admin.section.putAnImage') }}
        </div>
      </div>
      <v-img
        class="img-container my-2"
        height="140"
        width="300"
        :src="videoForm.image"
        v-if="putAnImage"
      >
        <p v-if="!videoForm.image">
          {{ $t('admin.training.admin.section.noThumbnailImage') }}
        </p>
      </v-img>

      <div class="cover__actions d-flex" v-if="putAnImage">
        <v-btn class="mr-3" color="primary" width="8.5rem" @click="openPictureDialog">
          <div class="px-2">
            {{ $t('globals.upload') }}
          </div>
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn color="error" class="px-2" width="8.5rem" @click="resetPicture">
          <div class="px-2">
            {{ $t('globals.reset') }}
          </div>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12" sm="12" v-if="content">
      <div class="">{{ $t('admin.training.admin.section.videoPreview') }}</div>
      <v-row>
        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <vimeo-video
              :key="`${content || 'video-content'}-key`"
              class="preview-video"
              :autoplay="false"
              :videoUrl="content"
              @getDuration="getDuration"
            />
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex align-center mt-8">
            <div class="ml-4">
              <p class="options__title">{{ $t('admin.training.admin.section.approximateTime') }}</p>
              <p>{{ `${durationRender}` }}</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" sm="12">
      <div>
        <v-btn icon color="secondary" @click="toggleValue = !toggleValue">
          <v-icon
            v-if="toggleValue"
            size="40"
            color="primary"
            :title="$t('admin.users.list.deactivate')"
          >
            mdi-toggle-switch
          </v-icon>
          <v-icon size="40" v-else :title="$t('admin.users.list.activate')">
            mdi-toggle-switch-off
          </v-icon>
        </v-btn>
        {{ $t('trainings.addResources') }}
      </div>
      <div>
        <v-btn
          v-if="toggleValue"
          class="mr-3 upload-file"
          color="primary"
          @click="documentDialog = true"
        >
          {{ $t('globals.upload') }}
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn
          :key="`doc-key-${doc.file}`"
          class="mr-3 my-2"
          color="white"
          v-for="doc in resourceList"
        >
          <span class="text-truncate" style="max-width: 100%">{{ doc.content || '' }}</span>
          <v-icon color="red" @click="removeDoucment(doc)">mdi-close</v-icon>
        </v-btn>
      </div>
    </v-col>
    <upload-pictures
      :dialog="pictureDialog"
      :is-multiple="false"
      @save="uploadPicture"
      @reset="resetPicture"
      @close="closePictureDialog"
    />
    <upload-files
      :is-multiple="true"
      :dialog="documentDialog"
      @save="uploadDocuments"
      @close="closeDocumentDialog"
    />
    <div class="d-flex justify-end pr-6 my-3">
      <v-btn color="error" outlined="" data-test-id="cancel" class="mx-2" @click="resetForm">
        Cancel</v-btn
      >
      <v-btn
        color="primary"
        :disabled="!content"
        data-test-id="save"
        @click="saveLesson"
        :loading="isSaving"
      >
        Save</v-btn
      >
    </div>
    <upload-videos
      :dialog="dialog.video"
      @save="uploadVideo"
      @close="closeDialog('video')"
      :isLoading="videoIsSending"
    />
  </div>
</template>

<script>
import { VideoTypeEnum } from '@/models/training/admin/section/video-type.enum';
import { VideoType } from '@/models/training/admin/section/video/resource-section-form.model';
import UploadVideos from '@/components/admin/upload-videos/UploadVideos.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  CURRENT_UPDATE_ITEM,
  GET_ALL_TRAININGS_LESSON_ADMIN,
  TRAINING_LESSON_ADMIN_MODULE,
  UPDATE_TRAINING_LESSON,
} from '@/stores/umanize-admin/actions/training-lesson/training-lesson.actions';
import VimeoVideo from '@/components/video/Video.vue';
import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import UploadFiles from '@/components/admin/upload-files/UploadFiles.vue';
import trainingLessonService from '@/services/training-lesson/training-lesson.service';
import {
  ADMIN_CONTENT_MODULE,
  DELETE_CONTENT_ITEM_VIDEO,
  UPLOAD_CONTENT_ITEM_VIDEO,
} from '@/stores/umanize-admin/actions/content/admin-content.actions';

export default {
  inject: ['listSectionUpdate'],
  name: 'VideoForm',
  components: {
    UploadVideos,
    VimeoVideo,
    UploadPictures,
    UploadFiles,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: { video: false },
    name: '',
    videoForm: new VideoType(),
    toggleValue: false,
    putAnImage: false,
    content: null,
    videoDialog: false,
    pictureDialog: false,
    duration: 0,
    durationRender: 0,
    documentDialog: false,
    resourceList: [],
    isUploadingDocument: false,
    isSaving: false,
    rawItem: null,
  }),
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(ADMIN_CONTENT_MODULE, ['itemIsSaving', 'video', 'videoIsSending']),
  },
  methods: {
    ...mapActions(TRAINING_LESSON_ADMIN_MODULE, [
      GET_ALL_TRAININGS_LESSON_ADMIN,
      UPDATE_TRAINING_LESSON,
      CURRENT_UPDATE_ITEM,
    ]),
    ...mapActions(ADMIN_CONTENT_MODULE, [UPLOAD_CONTENT_ITEM_VIDEO, DELETE_CONTENT_ITEM_VIDEO]),
    async uploadVideo(video) {
      const { eventId } = this.$route.params;
      const { trainingId } = this.item;

      await this[UPLOAD_CONTENT_ITEM_VIDEO]({
        eventId,
        trainingId,
        video,
      });
      this.content = this.video.playableUrl;

      this.closeDialog('video');
    },
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    collapLesson() {
      if (this.item) {
        this[CURRENT_UPDATE_ITEM](JSON.parse(JSON.stringify(this.item)));
      }
    },
    closeDialog(type) {
      this.dialog[type] = false;
    },
    async saveLesson() {
      this.isSaving = true;
      const { sectionId, trainingId } = this.item;
      const { eventId } = this.$route.params;
      const priorityUpdated = this.listSectionUpdate?.list?.find((e) => e.id === this.item.id)
        ?.priority;
      const saveItem = JSON.parse(
        JSON.stringify({
          ...this.item,
          priority: priorityUpdated ?? this.item.priority,
          type: VideoTypeEnum.VIDEO,
          name: this.name,
          content: {
            video: {
              content: this.content,
              thumbnailImage: this.videoForm.image,
              approximateTime: this.duration,
              ressources: this.resourceList,
            },
          },
        }),
      );
      await this.UPDATE_TRAINING_LESSON({
        eventId,
        sectionId,
        trainingId,
        upDateListLessionState: true,
        lesson: {
          ...saveItem,
        },
      });
      this.changeName(this.name);
      this.rawItem = JSON.parse(JSON.stringify(saveItem));
      this.isSaving = false;
      this.collapLesson();
    },
    getDuration(data) {
      this.duration = Math.round((data ?? 0) / 60);
      this.durationRender = new Date(data * 1000).toISOString().substring(11, 16);
    },
    openPictureDialog() {
      this.pictureDialog = true;
    },
    closePictureDialog() {
      this.dialogType = null;
      this.pictureDialog = false;
    },
    async uploadPicture(picture) {
      await this[UPLOAD_FILE](picture[0]);
      this.videoForm.image = this.file.url;
      this.closePictureDialog();
    },
    resetPicture() {
      this.videoForm = {};
    },
    closeDocumentDialog() {
      this.documentDialog = false;
    },
    async uploadDocuments(documents) {
      const newList = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (const document of documents) {
        await this[UPLOAD_FILE](document);
        newList.push({
          content: document.name,
          file: this.file.url,
        });
      }
      this.resourceList = [...this.resourceList, ...newList];
      this.closeDocumentDialog();
    },
    removeDoucment(doc) {
      const newList = this.resourceList.filter((ele) => ele.file !== doc.file);
      this.resourceList = newList;
    },
    init(item) {
      this.name = item.name;
      this.changeName(item.name);
      this.content = item.content.video.content;
      this.videoForm.image = item.content.video.thumbnailImage;
      if (this.videoForm.image) {
        this.putAnImage = 1;
      }
      this.resourceList = item.content.video?.ressources ?? [];
      if (this.resourceList.length) {
        this.toggleValue = true;
      }
    },
    resetForm() {
      this.$emit('reset-content', this.rawItem);
      this.init(this.rawItem);
      this.collapLesson();
    },
    changeName(name) {
      this.$emit('changeName', name);
    },
  },
  async mounted() {
    if (this.item) {
      const { eventId } = this.$route.params;
      const data = await trainingLessonService.get(
        eventId,
        this.item.trainingId,
        this.item.sectionId,
        this.item.id,
      );
      this.init(data);
      this.rawItem = JSON.parse(JSON.stringify(data));
    }
  },
  watch: {
    putAnImage(newVal) {
      if (!newVal) {
        this.videoForm.image = null;
      }
    },
    toggleValue(newVal) {
      if (!newVal) {
        this.resourceList = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '/src/styles/variables';
@import '/src/styles/core/mixins';

#section-video-form {
  .options {
    &__title {
      font-weight: bold;
    }
  }

  .img-container {
    height: 150px;
    width: 150px;
    border: 1px solid var(--v-gray-base);

    p {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
    }
  }

  .upload-file {
    width: 150px !important;
    text-align: left;
    justify-content: space-between;
    padding: 0 1rem !important;
  }

  .preview-video {
    width: 100%;
    height: 100%;
  }

  .cover {
    &__actions {
      .v-btn__content {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
</style>
