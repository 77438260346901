<template>
  <div id="quiz-table-question">
    <v-row>
      <v-col cols="6" sm="6">
        <div class="font-weight-bold">{{ $t('admin.training.admin.section.content') }}</div>
        <div class="py-1">{{ $t('admin.training.admin.section.question') }}</div>
        <div class="training-list-table__actions d-flex align-center">
          <v-btn
            :disabled="!canAdd"
            class="mr-4"
            color="primary"
            depressed
            large
            @click="openDialog('question')"
          >
            {{ $t('admin.training.trainingList.table.button.addQuestion') }}
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </div>
        <div class="table-list-section">
          <base-list
            :headers="headers"
            :hideHeaders="true"
            :items="tableItem"
            :itemsPerPage="10"
            :noDataText="$t('globals.datatable.noData')"
            :noResultsText="$t('globals.datatable.noResult')"
            :showFooter="true"
            backgroundColor="var(--v-adminBackground-base)"
            class="mt-4 pl-6"
            class-name="formation-table"
          >
            <template #body="data">
              <Draggable v-model="tableItem" tag="tbody">
                <tr v-for="(item, index) in data.items" :key="`row.${index}`" class="drag-item">
                  <template v-for="(col, idx) in data.headers">
                    <td
                      :key="`col.${index}.${idx}`"
                      :class="{
                        'drag-id': col.value == 'id',
                        'action-column': col.value == 'actions',
                      }"
                    >
                      <div
                        v-if="col.value == 'content'"
                        class="d-flex justify-space-between align-center"
                      >
                        <div
                          class="ml-2 text-truncate question-content"
                          style="width: calc(100% - 40px)"
                          v-html="item.content"
                        ></div>
                        <div class="mr-1">
                          <v-icon class="clickable" size="25"> mdi-menu</v-icon>
                        </div>
                      </div>
                      <span v-else-if="col.value == 'id'" class="float-number">{{
                        index + 1
                      }}</span>
                      <div
                        v-else-if="col.value == 'actions'"
                        class="d-flex justify-sm-space-around"
                      >
                        <v-btn icon @click="openDialog('question', item)">
                          <v-icon>mdi-cog-outline</v-icon>
                        </v-btn>
                        <v-btn icon @click="openDialog('delete', item)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                      <div v-else>{{ item[col.value] }}</div>
                    </td>
                  </template>
                </tr>
              </Draggable>
            </template>
          </base-list>
        </div>
      </v-col>
      <v-col cols="6" sm="6">
        <p class="text-center">{{ $t('admin.training.admin.section.quizOverview') }}</p>
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card class="card-overview">
              <div v-if="layout === 1" class="text-center px-4">
                <div class="overview">
                  <div>
                    <div class="overview__thank-you font-weight-bold">
                      {{ $t('admin.training.admin.section.thankYouForCompletingThisQuestion') }}
                    </div>
                    <div class="font-weight-regular overview__result">
                      {{ $t('admin.training.admin.section.yourResult') }}
                    </div>
                    <div class="red--text font-weight-bold overview__score">44%</div>
                    <v-btn class="overview__try-again my-2" color="primary" small width="7rem">
                      {{ $t('admin.training.admin.section.tryAgain') }}
                    </v-btn>
                  </div>
                </div>
              </div>
              <div v-if="layout === 2" class="text-center px-4">
                <div class="overview">
                  <div class="overview__left">
                    <v-img :src="imgSrc" class="left-border-radius" height="19rem" width="100%" />
                  </div>
                  <div class="overview__right">
                    <div class="overview__thank-you font-weight-bold">
                      {{ $t('admin.training.admin.section.thankYouForCompletingThisQuestion') }}
                    </div>
                    <div class="font-weight-regular overview__result">
                      {{ $t('admin.training.admin.section.yourResult') }}
                    </div>
                    <div class="red--text font-weight-bold overview__score">44%</div>
                    <v-btn class="overview__try-again my-2" color="primary" small width="7rem">
                      {{ $t('admin.training.admin.section.tryAgain') }}
                    </v-btn>
                  </div>
                </div>
              </div>

              <div v-if="layout === 3" class="text-center px-4">
                <div class="overview">
                  <div class="overview__right">
                    <div class="overview__thank-you font-weight-bold">
                      {{ $t('admin.training.admin.section.thankYouForCompletingThisQuestion') }}
                    </div>
                    <div class="font-weight-regular overview__result">
                      {{ $t('admin.training.admin.section.yourResult') }}
                    </div>
                    <div class="red--text font-weight-bold overview__score">44%</div>
                    <v-btn class="overview__try-again my-2" color="primary" small width="7rem">
                      {{ $t('admin.training.admin.section.tryAgain') }}
                    </v-btn>
                  </div>
                  <div class="overview__left">
                    <v-img :src="imgSrc" class="right-border-radius" height="19rem" width="100%" />
                  </div>
                </div>
              </div>

              <div v-if="layout === 4" class="text-center px-4">
                <div class="overview" v-bind:style="{ backgroundImage: 'url(' + imgSrc + ')' }">
                  <div class="layout-4">
                    <div class="overview__thank-you font-weight-bold">
                      {{ $t('admin.training.admin.section.thankYouForCompletingThisQuestion') }}
                    </div>
                    <div class="font-weight-regular overview__result">
                      {{ $t('admin.training.admin.section.yourResult') }}
                    </div>
                    <div class="red--text font-weight-bold overview__score">44%</div>
                    <v-btn class="overview__try-again my-2" color="primary" small width="7rem">
                      {{ $t('admin.training.admin.section.tryAgain') }}
                    </v-btn>
                  </div>
                </div>
              </div>

              <div v-if="layout === 5" class="text-center px-4">
                <div class="overview">
                  <div class="">
                    <v-img
                      :src="imgSrc"
                      class="rounded-lg mb-3 mx-auto"
                      height="7rem"
                      width="12rem"
                    />
                    <div class="overview__thank-you font-weight-bold">
                      {{ $t('admin.training.admin.section.thankYouForCompletingThisQuestion') }}
                    </div>
                    <div class="font-weight-regular overview__result">
                      {{ $t('admin.training.admin.section.yourResult') }}
                    </div>
                    <div class="red--text font-weight-bold overview__score">44%</div>
                    <v-btn class="overview__try-again my-2" color="primary" small width="7rem">
                      {{ $t('admin.training.admin.section.tryAgain') }}
                    </v-btn>
                  </div>
                </div>
              </div>

              <v-fade-transition>
                <v-overlay v-if="hover" absolute class="overlay clickable" color="#00B5A8">
                  <v-icon size="100" @click="openDialog('quizDemo')"
                    >mdi-magnify-plus-outline
                  </v-icon>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <v-dialog
      v-if="dialog.question"
      v-model="dialog.question"
      width="800"
      @click:outside="closeDialog('question')"
    >
      <v-card>
        <DialogQuestion
          :question="currentQuestion"
          @closeDialog="closeDialog('question')"
          @saveQuestion="saveQuestion"
        />
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="dialog.quizDemo"
      v-model="dialog.quizDemo"
      style="position: relative"
      width="800"
      @click:outside="closeDialog('quizDemo')"
    >
      <div class="dialog-quiz-demo">
        <div class="dialog-quiz-demo__close">
          <v-icon class="clickable" color="red" @click="closeDialog('quizDemo')"
            >mdi-window-close
          </v-icon>
        </div>
        <TrainingsSectionItemQuiz
          :data="previewItem.content.quizz.questions"
          :imgSrc="imgSrc"
          :item="previewItem"
          :layout="layout"
          :showFooter="false"
          :showTitle="false"
        ></TrainingsSectionItemQuiz>
      </div>
    </v-dialog>
    <dialog-confirmation
      :content="$t('admin.training.trainingList.popup.delete.deleteConfirmationDialog.section')"
      :title="$t('admin.partners.delete.deleteConfirmationDialog.title')"
      :visible="dialog.delete"
      @cancel="closeDialog('delete')"
      @confirm="deleteQuestion()"
    />
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import BaseList from '@/components/base-list/BaseList.vue';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import TrainingsSectionItemQuiz from '@/components/trainings/trainings-lesson/trainings-lesson-item/trainings-lesson-item-quiz/TrainingsSectionItemQuiz.vue';
import DialogQuestion from './dialog-question/DialogQuestion.vue';

export default {
  name: 'TableQuestion',
  components: {
    BaseList,
    DialogConfirmation,
    Draggable,
    DialogQuestion,
    TrainingsSectionItemQuiz,
  },
  props: {
    layout: {
      required: true,
    },
    imgSrc: {
      required: true,
    },
    item: {
      required: true,
    },
    previewItem: {
      required: true,
    },
    questionList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    expanded: [],
    quizData: [],
    canAdd: true,
    tableItem: [],
    dialog: {
      question: false,
      delete: false,
      quizDemo: false,
    },
    inputDialogSection: '',
    search: '',
    currentQuestion: {
      score: 0,
      order: 0,
      content: null,
      answer: [],
    },
  }),
  computed: {
    headers() {
      return [
        {
          align: 'start',
          value: 'id',
          width: '16px',
        },
        {
          align: 'start',
          value: 'content',
          width: '100%',
        },
        {
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '50px',
        },
      ];
    },
    isLoadingOrUploading() {
      return false;
    },
  },
  mounted() {
    this.tableItem = this.questionList;
  },
  methods: {
    expandedChange(params) {
      this.expanded = params;
    },
    onSearchChange(search) {
      this.search = search;
    },
    handleExpansion(item, data) {
      return { item, data };
    },
    cancel() {
      this.csvValidationDialog = false;
      this.file = null;
    },
    openDialog(name, item = null) {
      if (name === 'delete') {
        this.currentQuestion = {
          score: item.score,
          order: item.order,
          content: item.content,
          answers: item.answers,
        };
      } else if (name === 'question') {
        if (!item) {
          this.currentQuestion = {
            score: 0,
            order: 0,
            content: null,
            answers: [],
          };
        } else {
          this.currentQuestion = {
            score: item.score,
            order: item.order,
            content: item.content,
            answers: item.answers,
          };
        }
      }
      this.dialog[name] = true;
      if (name === 'quizDemo') {
        this.$emit('updateQuestion', this.tableItem);
        this.$emit('openQuestionOverlay', true);
      }
    },
    closeDialog(name) {
      this.dialog[name] = false;
    },
    deleteQuestion() {
      this.tableItem = this.tableItem.filter((ele) => ele.content !== this.currentQuestion.content);
      this.dialog.delete = false;
      this.currentQuestion = {
        score: 0,
        order: 0,
        content: null,
        answers: [],
      };
      this.$emit('updateQuestion', this.tableItem);
    },
    saveQuestion(data) {
      if (this.currentQuestion.content) {
        this.tableItem = this.tableItem.map((ele) => {
          if (ele.content === this.currentQuestion.content) {
            return data;
          }
          return ele;
        });
      } else {
        this.tableItem.push(data);
      }
      this.$emit('updateQuestion', this.tableItem);
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/core/variables';
@import '~@/styles/core/mixins';

@include admin-layout();
#quiz-table-question {
  .clickable {
    cursor: pointer;
  }

  .question-content {
    p {
      margin-bottom: 0;
    }
  }

  ::v-deep .v-data-table {
    th {
      padding-left: 0px !important;
    }

    td:nth-child(2) {
      padding-left: 0px !important;
    }
  }

  .drag-id {
    position: relative;
    width: 8px !important;
    font-weight: bold;

    .float-number {
      position: absolute;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .card-overview {
    box-shadow: unset !important;
  }

  .overview {
    border-radius: 10px;
    border: 1px solid var(--v-gray-base);
    box-shadow: 0 0 8px var(--v-gray-base);
    height: 19rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;

    .layout-4 {
      border-radius: 10px;
      background: white;
      padding: 1rem;
    }

    .right-border-radius {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .left-border-radius {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &__left {
      width: 45%;
    }

    &__right {
      width: 55%;
    }

    &__thank-you,
    &__result {
      font-size: $small-font-size;
    }

    &__score {
      margin-top: -5px;
    }

    &__try-again {
      .v-btn__content {
        font-size: $small-font-size;
      }
    }
  }

  .action-column {
    width: 80px !important;
  }

  .overlay {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }

  .overlay-btn {
    font-size: $base-xxx-large-font-size;
    font-weight: bold;
  }
}

.dialog-quiz-demo {
  position: relative;

  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 9999;
  }
}
</style>
