<template>
  <v-container class="mx-0 px-1">
    <v-col cols="6" sm="3">
      <div class="options__title">{{ $t('admin.training.admin.section.contentType') }}</div>
      <v-select
        v-model="contentType"
        :items="contentTypeList"
        item-text="label"
        item-value="id"
        placeholder="-"
      />
    </v-col>
    <template v-if="contentType === VideoTypeEnum.VIDEO">
      <VideoForm
        :key="`${item.id}-lesson-video`"
        :item="item"
        @changeName="changeName"
        @reset-content="resetContent"
      ></VideoForm>
    </template>
    <template v-else-if="contentType === VideoTypeEnum.WEBSITE_LINK">
      <WebsiteLinkForm
        :key="`${item.id}-lesson-web-link`"
        :item="item"
        @changeName="changeName"
        @reset-content="resetContent"
      ></WebsiteLinkForm>
    </template>
    <template v-else-if="contentType === VideoTypeEnum.FILE_REPOSITORY">
      <FileRepositoryForm
        :key="`${item.id}-file-reposity`"
        :item="item"
        @changeName="changeName"
        @reset-content="resetContent"
      ></FileRepositoryForm>
    </template>
    <template v-else-if="contentType === VideoTypeEnum.READING">
      <ReadingForm
        :key="`${item.id}-lesson-reading`"
        :item="item"
        @changeName="changeName"
        @reset-content="resetContent"
      ></ReadingForm>
    </template>
    <template v-else-if="contentType === VideoTypeEnum.QUIZ">
      <QuizForm
        :key="`${item.id}-lesson-quiz`"
        :item="item"
        @changeName="changeName"
        @reset-content="resetContent"
      ></QuizForm>
    </template>
    <template v-else-if="contentType === VideoTypeEnum.PDF">
      <PDFForm
        :key="`${item.id}-lesson-quiz`"
        :item="item"
        @changeName="changeName"
        @reset-content="resetContent"
      ></PDFForm>
    </template>
  </v-container>
</template>

<script>
import { capitalize } from 'vuetify-loader/lib/util';
import { VideoTypeEnum } from '@/models/training/admin/section/video-type.enum';
import { VideoType } from '@/models/training/admin/section/video/resource-section-form.model';
import VideoForm from './video-form/VideoForm.vue';
import WebsiteLinkForm from './website-link-form/WebsiteLinkForm.vue';
import FileRepositoryForm from './file-repository-form/FileRepositoryForm.vue';
import ReadingForm from './reading-form/ReadingForm.vue';
import QuizForm from './quiz-form/QuizForm.vue';
import PDFForm from './pdf-form/PDFForm.vue';

export default {
  name: 'SectionContent',
  components: {
    VideoForm,
    WebsiteLinkForm,
    FileRepositoryForm,
    ReadingForm,
    QuizForm,
    PDFForm,
  },
  data: () => ({
    contentType: VideoTypeEnum.VIDEO,
    videoForm: new VideoType(),
    toggleValue: true,
    VideoTypeEnum,
  }),
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contentTypeList() {
      return [
        { id: VideoTypeEnum.VIDEO, label: capitalize(VideoTypeEnum.VIDEO) },
        { id: VideoTypeEnum.READING, label: capitalize(VideoTypeEnum.READING) },
        { id: VideoTypeEnum.WEBSITE_LINK, label: capitalize(VideoTypeEnum.WEBSITE_LINK) },
        { id: VideoTypeEnum.QUIZ, label: 'Quiz' },
        { id: VideoTypeEnum.FILE_REPOSITORY, label: capitalize(VideoTypeEnum.FILE_REPOSITORY) },
        { id: VideoTypeEnum.PDF, label: 'PDF Viewer' },
      ];
    },
  },
  methods: {
    changeName(name) {
      this.$emit('changeName', name);
    },
    resetContent(item) {
      this.contentType = item.type;
      this.changeName(item.name);
    },
  },
  mounted() {
    this.contentType = this.item.type;
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/core/variables';
@import '~@/styles/core/mixins';

.options {
  &__title {
    font-weight: bold;
  }
}

.img-container {
  height: 150px;
  width: 150px;
  border: 1px solid var(--v-gray-base);

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
}

.upload-file {
  width: 150px !important;
  text-align: left;
  justify-content: space-between;
  padding: 0 1rem !important;
}
</style>
