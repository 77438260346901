<template>
  <div>
    <v-dialog content-class="v-dialog--overlay" v-model="open" width="500">
      <v-form v-model="valid">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $tc('admin.picture.title', count) }}</span>
          </v-card-title>

          <v-card-text class="form">
            <span>{{ $tc('admin.picture.uploadText', count) }}</span>

            <v-divider />

            <v-file-input
              v-model="files"
              :rules="pictureSizeRule"
              show-size
              :multiple="isMultiple"
              accept="image/png, image/jpeg, image/svg, image/bmp"
              :label="$tc('admin.picture.placeholder', count)"
              @change="onFileSelected"
              ref="myFile"
              data-test-id="input-file"
            />
          </v-card-text>

          <v-card-actions class="actions">
            <v-btn color="primary" class="mx-2" outlined @click="close" data-test-id="cancel">
              {{ $t('globals.cancel') }}
            </v-btn>

            <v-btn :disabled="isSaveDisabled" color="primary" @click="save" data-test-id="save">
              {{ $t('globals.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      content-class="v-dialog--overlay"
      data-test-id="modal"
      v-model="cropperDialog"
      width="500"
      eager
      persistent
    >
      <v-card>
        <v-card-text>
          <div class="cropper">
            <img class="cropper__image" :height="400" ref="imageCropper" />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn data-test-id="crop" class="primary" @click="handleCropClick">
            {{ $t('auth.profile.avatar.crop') }}
          </v-btn>

          <v-btn data-test-id="crop-cancel" color="primary" text @click="handleCancelClick">
            {{ $t('auth.profile.avatar.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
import VALIDATORS from '@/helpers/forms/validators.helper';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'UploadPictures',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 1920,
    },
    height: {
      type: Number,
      default: 1080,
    },
  },
  data: () => ({
    valid: false,
    files: null,
    pictureSizeRule: VALIDATORS.PICTURE,
    cropperDialog: false,
    cropper: null,
    imageSrc: null,
  }),
  computed: {
    count() {
      return this.isMultiple ? 2 : 1;
    },
    isSaveDisabled() {
      return (this.files || []).length <= 0 || !this.valid;
    },
    open: {
      get() {
        return this.dialog;
      },
      set() {
        return this.close();
      },
    },
  },
  methods: {
    save() {
      this.$emit('save', this.files);
      this.files = null;
    },
    close() {
      this.$emit('close');
      this.files = null;
    },
    saveImage() {
      const { type } = this.files[0];
      this.cropper
        .getCroppedCanvas({
          width: this.width,
          height: this.height,
        })
        .toBlob((blob) => {
          this.files[0] = blob;
          this.save();
        }, type);
    },
    handleCropClick() {
      this.saveImage();
      this.cropperDialog = false;
      this.cropper?.destroy();
    },
    handleCancelClick() {
      this.cropperDialog = false;
      this.cropper?.destroy();
    },
    onFileSelected() {
      if (this.files && this.height && this.width) {
        const file = this.files[0];
        if (!file || file.type.indexOf('image/') !== 0) return;

        const fileReader = new FileReader();

        fileReader.onload = (evt) => {
          const img = new Image();
          img.src = evt.target.result;
          img.onload = () => {
            if (this.height !== img.height || this.width !== img.width) {
              this.imageSrc = fileReader.result;
              this.cropper = new Cropper(this.$refs.imageCropper, {
                aspectRatio: this.width / this.height,
                viewMode: 2,
                dragMode: 'move',
                movable: false,
                zoomable: false,
                background: false,
                cropBoxMovable: true,
                cropBoxResizable: true,
                imageSmoothingQuality: 'medium',
              });
              this.cropper.replace(this.imageSrc);

              this.cropperDialog = true;
            }
          };
        };

        fileReader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

.form {
  display: flex;
  flex-direction: column;
}

.cropper {
  padding-top: 30px;
  max-width: 100%;

  &__image {
    width: 100%;
  }
}
</style>
