<template>
  <v-expansion-panels v-if="col.value === 'name'" v-model="copyItem.expanded" multiple>
    <v-expansion-panel :key="`pl-${item.id}`">
      <v-expansion-panel-header>
        <td>
          <div
            v-if="col.value === 'name'"
            class="d-flex table-list-section__item__content px-4 justify-space-between clickable"
          >
            <div class="d-flex align-center" style="overflow-wrap: anywhere">
              <div class="mr-2 d-flex align-center">
                <v-icon
                  size="30"
                  color="black"
                  v-if="copyItem.expanded && copyItem.expanded.length"
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon v-else size="30" color="black"> mdi-chevron-down</v-icon>
              </div>
              {{ name }}
            </div>
            <div class="d-flex align-center">
              <div class="table-list-section__item__content__action__wrap-drag px-4">
                <v-icon size="25"> mdi-menu</v-icon>
              </div>
              <div class="d-flex justify-sm-space-around ml-4">
                <v-btn icon @click.stop="openDialog('delete', item)">
                  <v-icon :title="$t('globals.delete')">mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </td>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="table-list-section__content">
          <SectionContent @changeName="changeName" :item="item"></SectionContent>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <dialog-confirmation
      :visible="dialog.delete"
      :title="$t('admin.partners.delete.deleteConfirmationDialog.title')"
      content="Are you sure you wish to delete this lesson ?"
      @cancel="closeDialog('delete')"
      @confirm="deleteLesson"
    />
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { VideoTypeEnum } from '@/models/training/admin/section/video-type.enum';
import {
  CURRENT_UPDATE_ITEM,
  GET_ALL_TRAININGS_LESSON_ADMIN,
  TRAINING_LESSON_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/training-lesson/training-lesson.actions';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import SectionContent from '../list-section-expand-item/SectionContent.vue';

export default {
  name: 'WrapListSection',
  components: {
    SectionContent,
    DialogConfirmation,
  },
  watch: {
    item(newVal) {
      this.copyItem = newVal;
    },
    dataItem: {
      handler(newDataItem) {
        this.$emit(
          'set-disabled-draggable',
          newDataItem.items.some((item) => item.expanded?.length >= 1),
        );
      },
      deep: true,
    },

    curUpdateLessonData(newVal) {
      if (!newVal) return;
      if (newVal?.id === this.copyItem.id) {
        this.copyItem.expanded = [];
        this[CURRENT_UPDATE_ITEM](null);
      }
    },
  },
  props: {
    dataItem: Object,
    col: Object,
    item: Object,
  },
  data: () => ({
    copyItem: {},
    deletedLesson: [],
    expanded: [],
    VideoTypeEnum,
    name: '',
    canAdd: true,
    dialog: {
      section: false,
      delete: false,
    },
    inputDialogSection: '',
    search: '',
  }),
  computed: {
    ...mapGetters(TRAINING_LESSON_ADMIN_MODULE, ['allTrainingLessonData', 'curUpdateLessonData']),
    headers() {
      return [
        {
          text: this.$t('admin.training.trainingList.table.headers.name'),
          align: 'start',
          value: 'name',
          width: '100%',
        },
      ];
    },
    isLoadingOrUploading() {
      return false;
    },
  },
  methods: {
    ...mapActions(TRAINING_LESSON_ADMIN_MODULE, [
      GET_ALL_TRAININGS_LESSON_ADMIN,
      CURRENT_UPDATE_ITEM,
    ]),
    closePanel() {
      this.copyItem.expanded = [];
    },
    onSearchChange(search) {
      this.search = search;
    },
    cancel() {
      this.csvValidationDialog = false;
      this.file = null;
    },
    openDialog(name, item = null) {
      if (name === 'section') {
        this.inputDialogSection = item?.name;
      }
      if (name === 'delete') {
        this.deletedLesson = item;
      }
      this.dialog[name] = true;
    },
    closeDialog(name) {
      this.inputDialogSection = '';
      this.dialog[name] = false;
    },
    redirectListParticipantsPage() {
      this.$router.push({
        name: 'TrainingFormationSEOListParticipants',
        params: {
          eventId: 'event-id',
          idTraining: 'id-participants',
        },
      });
    },
    deleteLesson() {
      this.$emit('delete-training-lesson', this.deletedLesson);
    },
    changeName(name) {
      this.name = name;
    },
  },
  mounted() {
    this.copyItem = this.item;
    this.name = this.copyItem.name ?? '';
  },
};
</script>

<style lang="scss">
@import '~@/styles/core/variables';
@import '~@/styles/core/mixins';

@include admin-layout();

#list-section {
  padding: 50px;
  min-height: calc(100vh - 6rem);

  .table-list-section {
    &__item {
      position: relative;
      background: transparent;

      &__index {
        position: absolute;
        left: -24px;
        transform: translateY(50%);
      }

      &__content {
        width: 100%;
        height: 57px;
        box-shadow: 0 0 8px var(--v-gray-base);

        &__action {
          &__wrap-drag {
            height: 100%;
            display: flex;
            border-right: 1px solid #7d7d7d;
          }
        }
      }
    }
  }

  // reset css
  .v-expansion-panels {
    .v-expansion-panel {
      .v-expansion-panel {
        &-header {
          padding: 0;
          margin: 0;
          min-height: unset;

          &__icon {
            display: none;
          }
        }
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.add-box-shadow {
  box-shadow: 0 0 8px var(--v-gray-base);
}

.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td:first-child {
  padding: 0 !important;
}

.formation-table {
  .v-data-table__wrapper {
    table {
      tbody {
        tr {
          td {
            padding: 0 !important;
          }
        }
      }
    }
  }
}
</style>
