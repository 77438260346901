var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"#access-file-repository-dialog"},[_c('base-list',{attrs:{"className":"table-content","backgroundColor":"var(--v-adminBackground-base)","items":_vm.fileItems,"loading":_vm.fileLoading,"headerProps":{ sortIcon: null },"headers":_vm.headers,"search":_vm.search,"hideDefaultHeader":_vm.hideDefaultHeader,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult'),"isSortDisabled":false,"itemPerPage":5},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.public",fn:function(ref){
    var item = ref.item;
return [_c('a',[_c('u',[_vm._v(" "+_vm._s(item.fileName)+" ")])])]}},{key:"item.actions",fn:function(ref){return [_c('div',{staticClass:"d-flex justify-space-around"},[_c('div',[_c('v-icon',[_vm._v("mdi-download")])],1),_c('div',[_c('v-icon',[_vm._v("mdi-delete")])],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }