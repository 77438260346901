<template>
  <v-container id="list-section">
    <div>
      <v-breadcrumbs
        :style="{ opacity: trainingSectionName ? 1 : 0 }"
        :items="breadcrumbs"
        large
        divider="<"
        class="ml-0 pl-0"
      >
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <div class="training-list-table__actions d-flex align-center">
      <v-btn
        class="mr-4"
        large
        depressed
        color="primary"
        @click="openDialog('section')"
        :disabled="!canAdd"
      >
        {{ $t('admin.training.trainingList.table.button.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
      <v-text-field
        class="actions__search"
        v-model="search"
        :label="$t('globals.search')"
        dense
        outlined
        hide-details
        prepend-inner-icon="mdi-magnify"
      />
    </div>
    <div class="table-list-section">
      <base-list
        class="mt-4 pl-6"
        class-name="formation-table"
        backgroundColor="var(--v-adminBackground-base)"
        :loading="isLoadingOrUploading"
        :headerProps="{ sortIcon: null }"
        :headers="headers"
        :search="search"
        :items="tableItem"
        :itemsPerPage="10"
        :footerProps="{
          itemsPerPageText: $t('globals.datatable.itemPerPage'),
          itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
          pageText: $t('globals.datatable.of'),
        }"
        :noDataText="$t('globals.datatable.noData')"
        :noResultsText="$t('globals.datatable.noResult')"
        :expanded="expanded"
        @expand-change="expandedChange"
      >
        <template #body="data">
          <Draggable tag="tbody" v-model="tableItem" :disabled="disabledDraggable">
            <tr v-for="item in data.items" :key="`row-${item.id}`" class="table-list-section__item">
              <div class="table-list-section__item__index">
                {{ tableItem.findIndex((ele) => ele.id === item.id) + 1 }}
              </div>
              <template v-for="col in data.headers">
                <div :key="`col.${item.id}${col}`">
                  <WrapListSection
                    :col="col"
                    :dataItem="data"
                    :item="item"
                    @delete-training-lesson="deleteTrainingLesson"
                    @set-disabled-draggable="setDisabledDraggable"
                  ></WrapListSection>
                </div>
              </template>
            </tr>
          </Draggable>
        </template>
      </base-list>
    </div>
    <v-dialog
      v-model="dialog.section"
      v-if="dialog.section"
      width="800"
      @click:outside="closeDialog('section')"
    >
      <v-card>
        <v-card-title> Add Lesson </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="inputDialogSection"
                :label="$t('admin.training.trainingList.popup.tab.name')"
                hide-details="auto"
                :persistent-placeholder="true"
                placeholder="-"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="actions">
          <v-btn data-test-id="cancel" color="primary" outlined @click="closeDialog('section')">
            {{ $t('globals.cancel') }}
          </v-btn>

          <v-btn data-test-id="add" color="primary" @click="submitDialog('section')">
            {{ $t('contentLibraries.add.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-confirmation
      :is-loading="false"
      :visible="dialog.delete"
      :title="$t('admin.partners.delete.deleteConfirmationDialog.title')"
      :content="$t('admin.training.trainingList.popup.delete.deleteConfirmationDialog.section')"
      @cancel="closeDialog('delete')"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Draggable from 'vuedraggable';

import BaseList from '@/components/base-list/BaseList.vue';
import i18n from '@/i18n';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import { VideoTypeEnum } from '@/models/training/admin/section/video-type.enum';
import {
  ADD_TRAINING_LESSON,
  DELETE_TRAINING_LESSON,
  GET_ALL_TRAININGS_LESSON_ADMIN,
  TRAINING_LESSON_ADMIN_MODULE,
  UPDATE_TRAINING_LESSON,
} from '@/stores/umanize-admin/actions/training-lesson/training-lesson.actions';
import {
  GET_TRAINING_SECTION,
  TRAINING_SECTION_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/training-section/training-section.actions';
import {
  GET_TRAINING,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';
import WrapListSection from './wrap-list-section/WrapListSection.vue';

export default {
  name: 'ListLesson',
  components: {
    BaseList,
    DialogConfirmation,
    Draggable,
    WrapListSection,
  },
  provide() {
    const listSectionUpdate = {};
    Object.defineProperty(listSectionUpdate, 'list', {
      enumerable: true,
      get: () => this.listNewValueWithNewPriority,
    });
    return {
      listSectionUpdate,
    };
  },
  data: () => ({
    trainingSectionName: false,
    listNewValueWithNewPriority: [],
    expanded: [],
    VideoTypeEnum,
    canAdd: true,
    disabledDraggable: false,
    breadcrumbs: [
      {
        text: i18n.t('admin.training.trainingList.title.listOfTraining'),
        disabled: false,
        href: '../../',
      },
      {
        text: i18n.t('admin.training.trainingList.title.formationSeo'),
        href: './',
        disabled: false,
      },
      {
        text: `${i18n.t('admin.training.trainingList.title.sectionTitle')} 1: Introduction`,
        disabled: true,
      },
    ],
    tableItem: [],
    dialog: {
      section: false,
      delete: false,
    },
    inputDialogSection: '',
    search: '',
    eventId: '',
    trainingId: '',
    sectionId: '',
  }),
  computed: {
    ...mapGetters(TRAINING_LESSON_ADMIN_MODULE, ['allTrainingLessonData']),
    ...mapGetters(TRAINING_SECTION_ADMIN_MODULE, ['trainingSectionData']),
    ...mapGetters(TRAINING_ADMIN_MODULE, ['trainingData']),
    headers() {
      return [
        {
          text: this.$t('admin.training.trainingList.table.headers.name'),
          align: 'start',
          value: 'name',
          width: '100%',
        },
      ];
    },
    isLoadingOrUploading() {
      return false;
    },
  },
  methods: {
    ...mapActions(TRAINING_LESSON_ADMIN_MODULE, [
      GET_ALL_TRAININGS_LESSON_ADMIN,
      ADD_TRAINING_LESSON,
      DELETE_TRAINING_LESSON,
      UPDATE_TRAINING_LESSON,
    ]),
    ...mapActions(TRAINING_SECTION_ADMIN_MODULE, [GET_TRAINING_SECTION]),
    ...mapActions(TRAINING_ADMIN_MODULE, [GET_TRAINING]),
    expandedChange(params) {
      this.expanded = params;
    },
    setDisabledDraggable(disabled) {
      this.disabledDraggable = disabled;
    },
    onSearchChange(search) {
      this.search = search;
    },
    cancel() {
      this.csvValidationDialog = false;
      this.file = null;
    },
    openDialog(name, item = null) {
      if (name === 'section') {
        this.inputDialogSection = item?.name;
      }
      this.dialog[name] = true;
    },
    closeDialog(name) {
      this.inputDialogSection = '';
      this.dialog[name] = false;
    },
    getItem(item) {
      if (!item) return {};
      return this.allTrainingLessonData.find((ele) => ele.id === item.id);
    },
    async submitDialog(name) {
      await this[ADD_TRAINING_LESSON]({
        eventId: this.eventId,
        trainingId: this.trainingId,
        sectionId: this.sectionId,
        lesson: {
          priority: 0,
          name: this.inputDialogSection,
          type: VideoTypeEnum.FILE_REPOSITORY,
        },
      });

      const listUpdate = this.tableItem.map((ele, index) => ({
        ...ele,
        priority: index + 1,
      }));
      const listPromise = listUpdate.map((ele) => {
        const { sectionId, trainingId } = ele;
        return this[UPDATE_TRAINING_LESSON]({
          eventId: this.eventId,
          sectionId,
          trainingId,
          lesson: { ...ele },
        });
      });
      await Promise.all(listPromise);
      await this[GET_ALL_TRAININGS_LESSON_ADMIN]({
        eventId: this.eventId,
        trainingId: this.trainingId,
        sectionId: this.sectionId,
      });
      this.inputDialogSection = '';
      this.tableItem = JSON.parse(JSON.stringify(this.allTrainingLessonData));
      this.dialog[name] = false;
    },
    async deleteTrainingLesson(item) {
      await this[DELETE_TRAINING_LESSON]({
        eventId: this.eventId,
        sectionId: this.sectionId,
        trainingId: this.trainingId,
        lesson: item,
      });

      this.closeDialog('delete');
      await this[GET_ALL_TRAININGS_LESSON_ADMIN]({
        eventId: this.eventId,
        trainingId: this.trainingId,
        sectionId: this.sectionId,
      });

      this.tableItem = this.allTrainingLessonData;
    },
  },
  async mounted() {
    const { eventId, idSection, idTraining } = this.$route.params;
    this.eventId = eventId;
    this.sectionId = idSection;
    this.trainingId = idTraining;

    await Promise.all([
      this[GET_ALL_TRAININGS_LESSON_ADMIN]({
        eventId,
        trainingId: idTraining,
        sectionId: idSection,
      }),
      this[GET_TRAINING_SECTION]({
        eventId,
        trainingId: idTraining,
        sectionId: idSection,
      }),
      this[GET_TRAINING]({
        eventId,
        trainingId: idTraining,
      }),
    ]);

    this.tableItem = JSON.parse(JSON.stringify(this.allTrainingLessonData));
    this.breadcrumbs.at(-1).text = this.trainingSectionData.name;
    this.trainingSectionName = this.trainingData?.name ?? '';
    this.breadcrumbs.at(-2).text = this.trainingSectionName;
  },
  watch: {
    async tableItem(newVal, olValue) {
      // create oldValue priority
      const oldValue = olValue.map((ele, index) => ({
        ...ele,
        priority: index,
      }));
      //
      const map = {};
      newVal.forEach((item) => {
        map[item.id] = item;
        return true;
      });
      this.allTrainingLessonData.forEach((item) => {
        map[item.id] = item;
        return true;
      });
      const result = Object.values(map);
      // create newValue priority
      const newList = result.map((ele, index) => ({
        ...ele,
        priority: index,
      }));
      this.listNewValueWithNewPriority = newList;

      // get list of priority change
      const listUpdate = newList.filter((ele) => {
        const oldItem = oldValue.find((itemEle) => itemEle.id === ele.id);
        if (oldItem?.priority !== ele?.priority) return true;
        return false;
      });
      const listPromise = listUpdate.map((ele) => {
        const { sectionId, trainingId } = ele;
        return this[UPDATE_TRAINING_LESSON]({
          eventId: this.eventId,
          sectionId,
          trainingId,
          lesson: { ...ele },
        });
      });
      await Promise.all(listPromise);
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/core/variables';
@import '~@/styles/core/mixins';

@include admin-layout();

#list-section {
  padding: 50px;
  min-height: calc(100vh - 6rem);

  .table-list-section {
    &__item {
      position: relative;
      background: transparent;

      &__index {
        position: absolute;
        left: -24px;
        transform: translateY(50%);
      }

      &__content {
        width: 100%;
        height: 57px;
        box-shadow: 0 0 8px var(--v-gray-base);

        &__action {
          &__wrap-drag {
            height: 100%;
            display: flex;
            border-right: 1px solid #7d7d7d;
          }
        }
      }
    }
  }

  // reset css
  .v-expansion-panels {
    .v-expansion-panel {
      .v-expansion-panel {
        &-header {
          padding: 0;
          margin: 0;
          min-height: unset;

          &__icon {
            display: none;
          }
        }
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.add-box-shadow {
  box-shadow: 0 0 8px var(--v-gray-base);
}

.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td:first-child {
  padding: 0 !important;
}

.formation-table {
  .v-data-table__wrapper {
    table {
      tbody {
        tr {
          td {
            padding: 0 !important;
          }
        }
      }
    }
  }
}
</style>
