<template>
  <div id="section-video-form">
    <v-col cols="12" sm="6">
      <div class="options__title">{{ $t('admin.training.admin.section.name') }}</div>
      <v-text-field
        v-model="name"
        :persistent-placeholder="true"
        hide-details="auto"
        placeholder="-"
        single-line
      />
    </v-col>
    <v-col cols="12" sm="12">
      <div class="options__title">{{ $t('admin.training.admin.section.content') }}</div>
      <div class="d-flex justify-center">
        <v-row>
          <v-col cols="6" sm="6">
            <v-text-field
              v-model="content"
              :label="$t('admin.training.admin.section.pdfUrl')"
              :persistent-placeholder="true"
              :rules="[(v) => !!v || 'PDF Url is required']"
              hide-details="auto"
              placeholder="-"
            />
          </v-col>
          <v-col cols="6" sm="6">
            <div class="d-flex align-center mt-4">
              <div class="mx-3">or</div>
              <v-btn class="mr-3 upload-file" color="primary" @click="dialog.upload = true">
                {{ $t('globals.upload') }}
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <v-col cols="12" sm="12">
      <div>
        <v-btn color="secondary" icon @click="toggleValue = !toggleValue">
          <v-icon
            v-if="toggleValue"
            :title="$t('admin.users.list.deactivate')"
            color="primary"
            size="40"
          >
            mdi-toggle-switch
          </v-icon>
          <v-icon v-else :title="$t('admin.users.list.activate')" size="40">
            mdi-toggle-switch-off
          </v-icon>
        </v-btn>
        {{ $t('trainings.addResources') }}
      </div>
      <div>
        <v-btn
          v-if="toggleValue"
          class="mr-3 upload-file"
          color="primary"
          @click="documentDialog = true"
        >
          {{ $t('globals.upload') }}
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn
          v-for="doc in resourceList"
          :key="`doc-key-${doc.file}`"
          class="mr-3 my-2"
          color="white"
        >
          <span class="text-truncate" style="max-width: 100%">{{ doc.content || '' }}</span>
          <v-icon color="red" @click="removeDoucment(doc)">mdi-close</v-icon>
        </v-btn>
      </div>
    </v-col>
    <upload-files
      :dialog="documentDialog"
      :is-multiple="true"
      @close="closeDocumentDialog"
      @save="uploadDocuments"
    />
    <div class="d-flex justify-end pr-6 my-3">
      <v-btn class="mx-2" color="error" data-test-id="cancel" outlined="" @click="resetForm">
        Cancel
      </v-btn>
      <v-btn
        :disabled="!content"
        :loading="isSaving"
        color="primary"
        data-test-id="save"
        @click="saveLesson"
      >
        Save
      </v-btn>
    </div>
    <upload-files
      :dialog="dialog.upload"
      :is-multiple="false"
      accepted-documents="application/pdf"
      @close="closeDialog('video')"
      @save="uploadPdf"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  CURRENT_UPDATE_ITEM,
  GET_ALL_TRAININGS_LESSON_ADMIN,
  TRAINING_LESSON_ADMIN_MODULE,
  UPDATE_TRAINING_LESSON,
} from '@/stores/umanize-admin/actions/training-lesson/training-lesson.actions';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import UploadFiles from '@/components/admin/upload-files/UploadFiles.vue';
import trainingLessonService from '@/services/training-lesson/training-lesson.service';
import {
  ADMIN_CONTENT_MODULE,
  DELETE_CONTENT_ITEM_VIDEO,
  UPLOAD_CONTENT_ITEM_VIDEO,
} from '@/stores/umanize-admin/actions/content/admin-content.actions';

export default {
  inject: ['listSectionUpdate'],
  name: 'PDFForm',
  components: {
    UploadFiles,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: { upload: false },
    name: '',
    toggleValue: false,
    content: null,
    documentDialog: false,
    resourceList: [],
    isUploadingDocument: false,
    isSaving: false,
    rawItem: null,
  }),
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(ADMIN_CONTENT_MODULE, ['itemIsSaving', 'video', 'videoIsSending']),
  },
  methods: {
    ...mapActions(TRAINING_LESSON_ADMIN_MODULE, [
      GET_ALL_TRAININGS_LESSON_ADMIN,
      UPDATE_TRAINING_LESSON,
      CURRENT_UPDATE_ITEM,
    ]),
    ...mapActions(ADMIN_CONTENT_MODULE, [UPLOAD_CONTENT_ITEM_VIDEO, DELETE_CONTENT_ITEM_VIDEO]),
    async uploadPdf(pdf) {
      await this[UPLOAD_FILE](pdf[0]);
      this.content = this.file.url;
      this.closeDialog('upload');
    },
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    collapLesson() {
      if (this.item) {
        this[CURRENT_UPDATE_ITEM](JSON.parse(JSON.stringify(this.item)));
      }
    },
    closeDialog(type) {
      this.dialog[type] = false;
    },
    async saveLesson() {
      this.isSaving = true;
      const { sectionId, trainingId } = this.item;
      const { eventId } = this.$route.params;
      const priorityUpdated = this.listSectionUpdate?.list?.find((e) => e.id === this.item.id)
        ?.priority;
      const saveItem = JSON.parse(
        JSON.stringify({
          ...this.item,
          priority: priorityUpdated ?? this.item.priority,
          type: 'pdf',
          name: this.name,
          content: {
            pdf: {
              content: this.content,
              ressources: this.resourceList,
            },
          },
        }),
      );
      await this.UPDATE_TRAINING_LESSON({
        eventId,
        sectionId,
        trainingId,
        upDateListLessionState: true,
        lesson: {
          ...saveItem,
        },
      });
      this.changeName(this.name);
      this.rawItem = JSON.parse(JSON.stringify(saveItem));
      this.isSaving = false;
      this.collapLesson();
    },
    closeDocumentDialog() {
      this.documentDialog = false;
    },
    async uploadDocuments(documents) {
      const newList = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (const document of documents) {
        await this[UPLOAD_FILE](document);
        newList.push({
          content: document.name,
          file: this.file.url,
        });
      }
      this.resourceList = [...this.resourceList, ...newList];
      this.closeDocumentDialog();
    },
    removeDoucment(doc) {
      const newList = this.resourceList.filter((ele) => ele.file !== doc.file);
      this.resourceList = newList;
    },
    init(item) {
      this.name = item.name;
      this.changeName(item.name);
      this.content = item.content.pdf.content;
      this.resourceList = item.content.pdf?.ressources ?? [];
      if (this.resourceList.length) {
        this.toggleValue = true;
      }
    },
    resetForm() {
      this.$emit('reset-content', this.rawItem);
      this.init(this.rawItem);
      this.collapLesson();
    },
    changeName(name) {
      this.$emit('changeName', name);
    },
  },
  async mounted() {
    if (this.item) {
      const { eventId } = this.$route.params;
      const data = await trainingLessonService.get(
        eventId,
        this.item.trainingId,
        this.item.sectionId,
        this.item.id,
      );
      this.init(data);
      this.rawItem = JSON.parse(JSON.stringify(data));
    }
  },
};
</script>

<style lang="scss" scoped>
@import '/src/styles/variables';
@import '/src/styles/core/mixins';

#section-video-form {
  .options {
    &__title {
      font-weight: bold;
    }
  }

  .img-container {
    height: 150px;
    width: 150px;
    border: 1px solid var(--v-gray-base);

    p {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
    }
  }

  .upload-file {
    width: 150px !important;
    text-align: left;
    justify-content: space-between;
    padding: 0 1rem !important;
  }

  .preview-video {
    width: 100%;
    height: 100%;
  }

  .cover {
    &__actions {
      .v-btn__content {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
</style>
