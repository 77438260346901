<template>
  <div id="website-link-form">
    <v-col cols="12" sm="6">
      <div class="options__title">{{ $t('admin.training.admin.section.name') }}</div>
      <v-text-field
        single-line
        v-model="name"
        hide-details="auto"
        :persistent-placeholder="true"
        placeholder="-"
        @change="changeName"
      />
    </v-col>
    <v-col cols="12" sm="12">
      <div class="options__title">{{ $t('admin.training.admin.section.content') }}</div>
      <div class="">{{ $t('admin.training.admin.section.putYourWebLink') }}</div>
      <v-row>
        <v-col cols="12" sm="6" class="mr-0 pr-2">
          <v-text-field
            single-line
            v-model="webLink"
            hide-details="auto"
            :persistent-placeholder="true"
            placeholder="-"
          />
        </v-col>
        <div class="d-flex align-end mb-2">
          <a
            v-if="webLink"
            class="trainings-section-item__wrap__right-item__note pt-1"
            :href="webLink"
            target="_blank"
            style="text-decoration: none"
          >
            <v-icon color="primary"> mdi-plus</v-icon>
          </a>
        </div>
      </v-row>
    </v-col>
    <v-col cols="6" sm="6">
      <div class="options__title">{{ $t('admin.training.admin.section.description') }}</div>
      <v-textarea rows="1"></v-textarea>
    </v-col>
    <v-col cols="12" sm="12">
      <div>
        <v-btn icon color="secondary" class="add-resource">
          <v-icon
            v-if="toggleValue"
            size="40"
            color="primary"
            :title="$t('admin.users.list.deactivate')"
            @click="toggleValue = !toggleValue"
          >
            mdi-toggle-switch
          </v-icon>
          <v-icon
            size="40"
            v-else
            :title="$t('admin.users.list.activate')"
            @click="toggleValue = !toggleValue"
          >
            mdi-toggle-switch-off
          </v-icon>
        </v-btn>
        {{ $t('trainings.addResources') }}
      </div>
      <div v-if="toggleValue">
        <v-btn class="mr-3 upload-file my-2" color="primary" @click="documentDialog = true">
          {{ $t('globals.upload') }}
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn
          :key="`doc-key-${doc.file}`"
          class="mr-3 my-2"
          color="white"
          v-for="doc in resourceList"
        >
          <span class="text-truncate" style="max-width: 100%">{{ doc.content || '' }}</span>
          <v-icon color="red" @click="removeDocument(doc)">mdi-close</v-icon>
        </v-btn>
      </div>
    </v-col>
    <div class="d-flex justify-end pr-6 my-3">
      <v-btn color="error" outlined="" data-test-id="cancel" class="mx-2" @click="resetLesson"
        >Cancel</v-btn
      >
      <v-btn color="primary" data-test-id="save" @click="saveLesson" :loading="isSaving">
        Save
      </v-btn>
    </div>
    <upload-files
      :is-multiple="true"
      :dialog="documentDialog"
      @save="uploadDocuments"
      @close="closeDocumentDialog"
    />
  </div>
</template>

<script>
import { VideoTypeEnum } from '@/models/training/admin/section/video-type.enum';
import { mapActions, mapGetters } from 'vuex';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import UploadFiles from '@/components/admin/upload-files/UploadFiles.vue';
import {
  CURRENT_UPDATE_ITEM,
  TRAINING_LESSON_ADMIN_MODULE,
  UPDATE_TRAINING_LESSON,
} from '@/stores/umanize-admin/actions/training-lesson/training-lesson.actions';
import trainingLessonService from '@/services/training-lesson/training-lesson.service';

export default {
  name: 'WebsiteLinkForm',
  inject: ['listSectionUpdate'],
  components: {
    UploadFiles,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    isSaving: false,
    name: null,
    webLink: null,
    toggleValue: false,
    rawItem: null,
    resourceList: [],
    documentDialog: false,
  }),
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
  },
  methods: {
    ...mapActions(TRAINING_LESSON_ADMIN_MODULE, [UPDATE_TRAINING_LESSON, CURRENT_UPDATE_ITEM]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    collapLesson() {
      if (this.item) {
        this[CURRENT_UPDATE_ITEM](JSON.parse(JSON.stringify(this.item)));
      }
    },
    closeDocumentDialog() {
      this.documentDialog = false;
    },
    async uploadDocuments(documents) {
      const newList = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (const document of documents) {
        await this[UPLOAD_FILE](document);
        newList.push({
          content: document.name,
          file: this.file.url,
        });
      }
      this.resourceList = [...this.resourceList, ...newList];
      this.closeDocumentDialog();
    },
    removeDocument(doc) {
      const newList = this.resourceList.filter((ele) => ele.file !== doc.file);
      this.resourceList = newList;
    },
    async saveLesson() {
      this.isSaving = true;
      const { sectionId, trainingId } = this.item;
      const { eventId } = this.$route.params;
      const priorityUpdated = this.listSectionUpdate?.list?.find((e) => e.id === this.item.id)
        ?.priority;
      const saveItem = JSON.parse(
        JSON.stringify({
          ...this.item,
          priority: priorityUpdated ?? this.item.priority,
          type: VideoTypeEnum.WEBSITE_LINK,
          name: this.name,
          content: {
            websiteLink: {
              content: this.webLink,
              ressources: this.resourceList,
            },
          },
        }),
      );
      await this.UPDATE_TRAINING_LESSON({
        eventId,
        sectionId,
        trainingId,
        upDateListLessionState: true,
        lesson: saveItem,
      });
      this.changeName(this.name);
      this.rawItem = saveItem;
      this.isSaving = false;
      this.collapLesson();
    },
    resetLesson() {
      this.$emit('reset-content', this.rawItem);
      this.init(this.rawItem);
      this.collapLesson();
    },
    init(item) {
      this.name = item.name;
      this.changeName(item.name);
      this.webLink = item.content.websiteLink.content;
      this.resourceList = item.content.websiteLink?.ressources ?? [];
      if (this.resourceList?.length) {
        this.toggleValue = true;
      }
    },
    changeName(name) {
      this.$emit('changeName', name);
    },
  },
  async mounted() {
    if (this.item) {
      const { eventId } = this.$route.params;
      const data = await trainingLessonService.get(
        eventId,
        this.item.trainingId,
        this.item.sectionId,
        this.item.id,
      );
      this.init(data);
      this.rawItem = JSON.parse(JSON.stringify(data));
    }
  },
  watch: {
    toggleValue(newVal) {
      if (!newVal) {
        this.resourceList = [];
      }
    },
  },
};
</script>

<style lang="scss">
@import '/src/styles/variables';
@import '/src/styles/core/mixins';

#website-link-form {
  .add-resource {
    .v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
      opacity: 0;
    }

    .theme--light.v-icon:focus::after {
      opacity: 0;
    }
  }

  .options {
    &__title {
      font-weight: bold;
    }
  }

  .img-container {
    height: 150px;
    width: 150px;
    border: 1px solid var(--v-gray-base);

    p {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
    }
  }

  .upload-file {
    width: 150px !important;
    text-align: left;
    justify-content: space-between;
    padding: 0 1rem !important;
  }
}
</style>
