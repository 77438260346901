<template>
  <div id="section-quiz-form">
    <v-col cols="12" sm="6">
      <div class="options__title">{{ $t('admin.training.admin.section.name') }}</div>
      <v-text-field
        single-line
        v-model="name"
        hide-details="auto"
        :persistent-placeholder="true"
        placeholder="-"
      />
    </v-col>
    <v-col cols="12" sm="12" v-if="item">
      <TableQuestion
        @updateQuestion="updateQuestion"
        @openQuestionOverlay="openQuestionOverlay"
        :item="item"
        :previewItem="previewItem"
        :key="questionList.length"
        :layout="layout"
        :img-src="imgSrc"
        :questionList="questionList"
      />
    </v-col>
    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="12" sm="4">
          <div class="options__title">
            {{ $t('admin.training.admin.section.allowedAttempts') }}
            <span class="options__title__subtitle">{{
              $t('admin.training.admin.section.allowedAttemptsUndefined')
            }}</span>
          </div>
          <v-text-field
            class="input-allowed-attempts"
            type="number"
            :min="0"
            v-model.number="allowedAttempts"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <div class="options__title">{{ $t('admin.training.admin.section.passingGrade') }}</div>
          <v-select
            class="select-pass-grade"
            v-model="passingGrade"
            :items="passingGradeList"
            placeholder="-"
          >
            <template slot="selection" slot-scope="data">
              <span class="fa"></span>
              {{ data.item }}%
            </template>
            <template slot="item" slot-scope="data">
              <!-- HTML that describe how select should render items when the select is open -->
              {{ data.item }}%
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="options__title">{{ $t('admin.training.admin.section.approximateTime') }}</div>
          <v-menu
            ref="menu"
            v-model="menuTime"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="approximateTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="app-time"
                single-line
                hide-details="auto"
                v-model="approximateTime"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menuTime"
              v-model="approximateTime"
              format="24hr"
              full-width
              @click:minute="$refs.menu.save(approximateTime)"
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="6">
      <div class="options__title">{{ $t('admin.training.admin.section.layout') }}</div>
      <div>{{ $t('admin.training.admin.section.selectYourLayout') }}</div>
      <div class="d-flex">
        <div class="mr-2 clickable">
          <img v-if="layout === 1" src="~@assets/icons/svg/Quiz_Layout_1_Select.svg" />
          <img v-else @click="layout = 1" src="~@assets/icons/svg/Quiz_Layout_1_No-select.svg" />
        </div>
        <div class="mr-2 clickable">
          <img v-if="layout === 2" src="~@assets/icons/svg/Quiz_Layout_2_Select.svg" />
          <img v-else @click="layout = 2" src="~@assets/icons/svg/Quiz_Layout_2_No-select.svg" />
        </div>
        <div class="mr-2 clickable">
          <img v-if="layout === 3" src="~@assets/icons/svg/Quiz_Layout_3_Select.svg" />
          <img v-else @click="layout = 3" src="~@assets/icons/svg/Quiz_Layout_3_No-select.svg" />
        </div>
        <div class="mr-2 clickable">
          <img v-if="layout === 4" src="~@assets/icons/svg/Quiz_Layout_4_Select.svg" />
          <img v-else @click="layout = 4" src="~@assets/icons/svg/Quiz_Layout_4_No-select.svg" />
        </div>
        <div class="mr-2 clickable">
          <img v-if="layout === 5" src="~@assets/icons/svg/Quiz_Layout_5_Select.svg" />
          <img v-else @click="layout = 5" src="~@assets/icons/svg/Quiz_Layout_5_No-select.svg" />
        </div>
      </div>
    </v-col>
    <v-col cols="12" sm="6">
      <div class="options__title">{{ $t('admin.training.admin.section.downloadImage') }}</div>
      <v-img class="img-container my-2" height="140" width="300" :src="image">
        <p v-if="!image">
          {{ $t('admin.training.admin.section.noPicture') }}
        </p>
      </v-img>

      <div class="cover__actions d-flex">
        <v-btn class="mr-3" color="primary" width="8.5rem" @click="pictureDialog = true">
          <div class="px-2">
            {{ $t('globals.upload') }}
          </div>
          <v-icon size="17">mdi-upload</v-icon>
        </v-btn>
        <v-btn color="error" class="px-2" width="8.5rem" @click="resetPicture()">
          <div class="px-2">
            {{ $t('globals.reset') }}
          </div>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-col>
    <div class="d-flex justify-end pr-6 my-3">
      <v-btn color="error" outlined="" data-test-id="cancel" class="mx-2" @click="resetQuiz"
        >Cancel</v-btn
      >
      <v-btn color="primary" data-test-id="save" @click="saveQuiz" :loading="isSaving">
        Save
      </v-btn>
    </div>
    <upload-pictures
      :dialog="pictureDialog"
      :is-multiple="false"
      @save="uploadPicture"
      @reset="resetPicture"
      @close="closePictureDialog"
    />
  </div>
</template>

<script>
import { VideoType } from '@/models/training/admin/section/video/resource-section-form.model';
import trainingLessonService from '@/services/training-lesson/training-lesson.service';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import { mapActions, mapGetters } from 'vuex';
import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import {
  CURRENT_UPDATE_ITEM,
  GET_ALL_TRAININGS_LESSON_ADMIN,
  TRAINING_LESSON_ADMIN_MODULE,
  UPDATE_TRAINING_LESSON,
} from '@/stores/umanize-admin/actions/training-lesson/training-lesson.actions';
import { VideoTypeEnum } from '@/models/training/admin/section/video-type.enum';
import TableQuestion from './table-question/TableQuestion.vue';

export default {
  name: 'QuizForm',
  inject: ['listSectionUpdate'],
  components: {
    TableQuestion,
    UploadPictures,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    time: null,
    menuTime: false,
    isSaving: false,
    pictureDialog: false,
    name: '',
    videoForm: new VideoType(),
    toggleValue: true,
    description: '',
    layout: 1,
    image: '',
    imgSrc: 'https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg',
    passingGrade: null,
    allowedAttempts: null,
    approximateTime: null,
    questionList: [],
    rawQuiz: null,
    previewItem: {},
  }),
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    passingGradeList() {
      return [...Array(10).keys()].map((ele) => ele * 10);
    },
  },
  methods: {
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    ...mapActions(TRAINING_LESSON_ADMIN_MODULE, [
      GET_ALL_TRAININGS_LESSON_ADMIN,
      UPDATE_TRAINING_LESSON,
      CURRENT_UPDATE_ITEM,
    ]),
    collapLesson() {
      if (this.item) {
        this[CURRENT_UPDATE_ITEM](JSON.parse(JSON.stringify(this.item)));
      }
    },
    updateQuestion(data) {
      this.questionList = data;
    },
    openQuestionOverlay(e) {
      if (e) {
        this.previewItem = JSON.parse(
          JSON.stringify({
            ...this.item,
            priority: this.item.priority,
            type: VideoTypeEnum.QUIZ,
            name: this.name,
            content: {
              quizz: {
                approximateTime: this.getApproximateTime(),
                image: this.image,
                layout: this.layout,
                passingGrade: this.passingGrade,
                allowedAttempts: this.allowedAttempts,
                questions: this.listSubmitQuestion,
              },
            },
          }),
        );
      }
    },
    async saveQuiz() {
      this.isSaving = true;
      const { sectionId, trainingId } = this.item;
      const { eventId } = this.$route.params;
      const priorityUpdated = this.listSectionUpdate?.list?.find((e) => e.id === this.item.id)
        ?.priority;
      const saveItem = JSON.parse(
        JSON.stringify({
          ...this.item,
          priority: priorityUpdated ?? this.item.priority,
          type: VideoTypeEnum.QUIZ,
          name: this.name,
          content: {
            quizz: {
              approximateTime: this.getApproximateTime(),
              image: this.image,
              layout: this.layout,
              passingGrade: this.passingGrade,
              allowedAttempts: this.allowedAttempts,
              questions: this.questionList,
            },
          },
        }),
      );
      await this.UPDATE_TRAINING_LESSON({
        eventId,
        sectionId,
        trainingId,
        upDateListLessionState: true,
        lesson: saveItem,
      });
      this.changeName(this.name);
      this.rawItem = saveItem;
      this.isSaving = false;
      this.collapLesson();
    },
    getApproximateTime() {
      if (this.approximateTime) {
        const val = String(this.approximateTime).split(':');
        return Number(val[0]) * 60 + Number(val[1]);
      }
      return 0;
    },
    convertMinuteToTime(value) {
      if (!value) return '00:00';
      const hour = Math.floor(value / 60);
      const remainingMinutes = value % 60;
      const hhmm = `${hour.toString().padStart(2, '0')}:${remainingMinutes
        .toString()
        .padStart(2, '0')}`;
      return hhmm;
    },
    async uploadPicture(picture) {
      await this[UPLOAD_FILE](picture[0]);
      this.image = this.file.url;
      this.imgSrc = this.image;
      this.closePictureDialog();
    },
    resetPicture() {
      this.image = null;
      this.imgSrc = 'https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg';
    },
    closePictureDialog() {
      this.pictureDialog = false;
    },
    init(item) {
      this.name = item.name;
      this.changeName(item.name);
      this.passingGrade = item.content.quizz.passingGrade ?? 0;
      this.allowedAttempts = item.content.quizz.allowedAttempts ?? 0;
      this.approximateTime = this.convertMinuteToTime(
        Number(item.content.quizz.approximateTime ?? 0),
      );
      this.layout = item.content?.quizz?.layout === 0 ? 1 : item.content.quizz.layout ?? 1;
      this.image = item.content.quizz.image ?? '';
      if (this.image) {
        this.imgSrc = this.image;
      }
      this.questionList = item.content.quizz?.questions ?? [];
    },
    resetQuiz() {
      this.$emit('reset-content', this.rawQuiz);
      this.init(this.rawQuiz);
      this.collapLesson();
    },
    changeName(name) {
      this.$emit('changeName', name);
    },
  },
  async mounted() {
    if (this.item) {
      const { eventId } = this.$route.params;
      const data = await trainingLessonService.get(
        eventId,
        this.item.trainingId,
        this.item.sectionId,
        this.item.id,
      );
      this.init(data);
      this.rawQuiz = JSON.parse(JSON.stringify(data));
      this.previewItem = JSON.parse(JSON.stringify(data));
    }
  },
};
</script>

<style lang="scss">
@import '/src/styles/variables';
@import '/src/styles/core/mixins';

#section-quiz-form {
  .options {
    &__title {
      font-weight: bold;
      &__subtitle {
        font-size: 10px;
        color: gray;
      }
    }
  }

  .select-pass-grade {
    padding-top: 8px;
    margin-top: 0px;
    font-size: 14px;

    .v-select__selection--comma {
      margin-bottom: 0;
    }
  }

  .input-allowed-attempts {
    padding-top: 8px;
    margin-top: 0;
    font-size: 14px;
  }

  .app-time.v-input {
    padding: 4px 4px 7px 0;
  }

  .clickable {
    cursor: pointer;
  }

  .img-container {
    height: 150px;
    width: 150px;
    border: 1px solid var(--v-gray-base);

    p {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
    }
  }
}
</style>
